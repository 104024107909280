import React from 'react';
import { graphql } from 'gatsby';
import Vertical from './Vertical';
import Horizontal from './Horizontal';

export default ({ variant, ...other }) => {
  const variants = {
    Left: () => <Horizontal {...other} flipped />,
    Right: () => <Horizontal {...other} />,
    Bottom: () => <Vertical {...other} />,
  };
  return variants[variant || 'Bottom']();
};

export const query = graphql`
  fragment CaptionsModuleFragment on ContentfulCaptionsModule {
    id
    slug
    colorMode
    variant
    text {
      json
    }
    images {
      id
      ...ImageGroupFragment
    }
    background {
      ...ImageGroupFragment
    }
    captions {
      id
      title
      description {
        description
      }
    }
  }
`;
