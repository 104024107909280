import React from 'react';
import css from 'src/theme/css';
import ImageGroup from 'src/components/ImageGroup';
import ModuleWrapper from 'src/components/ModuleWrapper';
import RichText from 'src/components/RichText';
import Text from 'src/components/Text';
import { Grid, Flex, Box } from 'src/components/FlexBox';

export default ({ text, images, alignment, captions, flipped, ...other }) => {
  return (
    <ModuleWrapper
      {...other}
      justifyContent="center"
      flexDirection="column"
      css={css({ overflow: 'hidden', py: 'sectionMargin', px: 'pageMargin' })}
    >
      <RichText
        textAlign="center"
        alignItems="center"
        {...text}
        css={css({ mb: 'sectionMargin' })}
      />
      <Grid
        gx={5}
        gy={5}
        gridTemplateColumns={['auto', flipped ? '2fr 3fr' : '3fr 2fr']}
        gridAutoFlow="row dense"
      >
        <ImageGroup
          {...images[0]}
          css={css({
            justifySelf: flipped ? 'start' : 'end',
            alignSelf: 'start',
            width: ['100%', '150%', null, '130%', null, '100%'],
            gridColumn: ['auto', flipped ? '2 / 3' : '1 / 2'],
          })}
        />
        <Flex
          flexDirection="column"
          gy={[4, null, 5]}
          css={css({ gridColumn: ['auto', flipped ? '1 / 2' : '2 / 3'] })}
        >
          {captions.map(({ title, description }, index) => (
            <Box
              flexDirection="column"
              textAlign={['center', 'start']}
              alignItems={['center', 'start']}
            >
              {index !== 0 && (
                <hr
                  css={css({
                    border: 'none',
                    height: '1px',
                    width: '100%',
                    bg: 'modeAlpha.2',
                  })}
                />
              )}
              <Text variant="heading.s" css={css({ mt: 3 })}>
                {title}
              </Text>
              <Text css={css({ mt: 3, maxWidth: '20em' })}>
                {description.description}
              </Text>
            </Box>
          ))}
        </Flex>
      </Grid>
    </ModuleWrapper>
  );
};
