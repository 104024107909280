import React, { useState, useRef } from 'react';
import css from 'src/theme/css';
import get from 'lodash/get';
import { useStore } from 'src/components/GlobalState';
import useAnimationFrame from 'src/hooks/useAnimationFrame';
import useBounds from 'src/hooks/useBounds';
import ModuleWrapper from 'src/components/ModuleWrapper';
import ImageGroup from 'src/components/ImageGroup';
import Layers from 'src/components/Layers';
import Text from 'src/components/Text';
import FrameContainer from 'src/components/FrameContainer';
import RichText from 'src/components/RichText';
import { Box, Grid } from 'src/components/FlexBox';
import { progress, clamp } from '@popmotion/popcorn';
import { useBreakpoint } from '../Breakpoints/index';

export default ({ text, alignment, images, captions, fullWidth, ...other }) => {
  const progressBars = [useRef(), useRef(), useRef()];
  const [index, setIndex] = useState(0);
  const reflow = useStore((state) => state.reflow);
  const isLarge = useBreakpoint(0);
  const [ref, bounds] = useBounds();
  const singleImage = images.length <= 1;
  useAnimationFrame(() => {
    const p = clamp(
      0,
      1,
      progress(0, bounds.current.height - reflow.height, -bounds.current.y)
    );

    setIndex(clamp(0, images.length - 1, Math.floor(p * images.length)));

    progressBars.forEach((ref, index) => {
      if (ref.current) {
        const scale = p * progressBars.length - index;
        ref.current.style.transform = `scaleX(${scale})`;
      }
    });
  });

  const Caption = ({ i, title, description, active, progressBarRef }) => {
    const CustomTag = singleImage ? 'div' : React.Fragment;

    return (
      <CustomTag>
        <Text
          variant="heading.xs"
          css={css({
            position: 'relative',
            mb: 3,
            mt: singleImage ? [4, 0] : '',
            gridArea: ['a', `a-${i}`],
            opacity: [active || singleImage ? 1 : 0, 1],
          })}
        >
          {title}
        </Text>
        {!singleImage && (
          <Box
            css={css({
              position: 'relative',
              mb: 3,
              bg: 'modeAlpha.1',
              gridArea: ['b', `b-${i}`],
              overflow: 'hidden',
              opacity: [active || singleImage ? 1 : 0, 1],
            })}
          >
            <div
              css={css({
                position: 'relative',
                width: '100%',
                height: '1px',
                bg: 'mode',
                transformOrigin: 'center left',
              })}
              ref={progressBarRef}
            />
          </Box>
        )}
        <Text
          css={css({
            mb: [3, 0],
            color: 'modeAlpha.8',
            transition: 'opacity 200ms',
            gridArea: ['c', `c-${i}`],
            opacity: [
              active || singleImage ? 1 : 0,
              active || singleImage ? 1 : 0.4,
            ],
          })}
        >
          {description.description}
        </Text>
      </CustomTag>
    );
  };

  const renderedText = (
    <RichText
      textAlign="center"
      alignItems="center"
      size="m"
      {...text}
      css={css({
        gridArea: 'text',
        mx: 'pageMargin',
        mb: [5, 0],
        mt: [0, 'sectionMargin'],
      })}
    />
  );

  return (
    <ModuleWrapper
      {...other}
      justifyContent="center"
      flexDirection="column"
      css={css({ overflow: 'visible' })}
    >
      {isLarge && renderedText}
      <Box
        justifyContent="center"
        css={css({ height: images.length > 1 ? '300vh' : '' })}
        ref={ref}
      >
        <Grid
          gridTemplateColumns={['1fr', '1fr 1fr 1fr']}
          gridTemplateRows={['auto 1fr auto auto auto', '1fr auto auto auto']}
          gridTemplateAreas={[
            `"text" "image" "a" "b" "c"`,
            `"image image image" "a-0 a-1 a-2" "b-0 b-1 b-2" "c-0 c-1 c-2"`,
          ]}
          css={css({
            position: singleImage ? '' : 'sticky',
            columnGap: [4, null, null, 4, 5],
            top: 0,
            px: 'pageMargin',
            py: 5,
            width: '100%',
            maxWidth: 'pageMaxWidth',
            height: singleImage ? '' : '100vh',
            overflow: 'hidden',
          })}
        >
          {!isLarge && renderedText}
          <Layers css={css({ gridArea: 'image', mb: 5 })}>
            {images.map((image, i) => (
              <Box
                key={image.id}
                css={{
                  position: singleImage ? 'relative' : '',
                  top: singleImage ? 'auto' : '',
                  left: singleImage ? 'auto' : '',
                  height: singleImage ? 'auto' : '',
                }}
              >
                <FrameContainer
                  fullWidth={singleImage}
                  ratio={
                    get(image, 'largeImage.file.details.image.height', 9) /
                    get(image, 'largeImage.file.details.image.width', 16)
                  }
                  css={css({
                    width: '100%',
                    height: '100%',
                  })}
                >
                  <ImageGroup
                    {...image}
                    objectFit="contain"
                    css={css({
                      transitionProperty: 'opacity, transform',
                      transitionDuration: '500ms',
                      transitionTimingFunction: ({ ease }) => ease.expo,
                      zIndex: i === index ? 1 : 0,
                      opacity: i === index ? 1 : 0,
                      transform: `translateX(calc(${i - index} * 50vw))`,
                      borderRadius: '15px',
                      overflow: 'hidden',
                    })}
                  />
                </FrameContainer>
              </Box>
            ))}
          </Layers>
          {captions.map((caption, i) => (
            <Caption
              {...caption}
              key={caption.id}
              i={i}
              active={index === i}
              progressBarRef={progressBars[i]}
            />
          ))}
        </Grid>
      </Box>
    </ModuleWrapper>
  );
};
